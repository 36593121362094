<template>
    <div>
        <!-- FILE UPLOAD START -->
        <section id="knowledge-base-search">
            <b-card
                no-body
                class="knowledge-base-bg text-center"
                :style="{
                    backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
                }"
            >
                <b-card-body class="card-body">
                    <h2 class="text-primary">File Uploading Service</h2>
                    <b-card-text class="mb-2">
                        <span class="font-weight-bolder"
                            >Maximum File Size is 10 MB
                        </span>
                    </b-card-text>

                    <!-- form -->
                    <b-form class="kb-search-input">
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                                <feather-icon icon="SearchIcon" />
                            </b-input-group-prepend>
                            <b-form-file
                                v-model="file"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                @input="fileUpload()"
                            />
                        </b-input-group>
                    </b-form>
                    <!-- 
                    <b-form class="kb-search-input">
                        <b-button
                            variant="primary"
                            @click="
                                date = null;
                                inputLat = null;
                                inputLng = null;
                                fileUpload();
                            "
                        >
                            Submit
                        </b-button>
                    </b-form> -->

                    <!-- form -->
                </b-card-body>

                <b-card-text class="mb-2">
                    <span class="font-weight-bolder">
                        <a :href="link">
                            {{
                                link
                                    ? link
                                    : "Link will Appear Here after Upload"
                            }}
                        </a>
                    </span>
                </b-card-text>
            </b-card>
        </section>
        <!--/ FILE UPLOAD ENDS -->

        <section>
            <b-row class="match-height">
                <!-- GOOGLE MAP START -->

                <!-- GOOGLE MAP ENDS -->

                <!-- PHOTO DISPLAY START -->
                <b-col md="12">
                    <b-row>
                        <b-col md="4">
                            <h2>Original Image</h2>
                            <b-img
                                :src="link"
                                class="mb-2"
                                fluid
                                alt="Image will show here after upload"
                            />
                        </b-col>
                        <b-col md="4">
                            <h2>Width Altered</h2>

                            <b-img
                                :src="`${link}?x-oss-process=image/resize,w_500/quality,q_80`"
                                class="mb-2"
                                fluid
                                alt="Image will show here after upload"
                            />
                        </b-col>
                        <b-col md="4">
                            <h2>Brightness Altered</h2>

                            <b-img
                                :src="`${link}?x-oss-process=image/bright,50`"
                                class="mb-2"
                                fluid
                                alt="Image will show here after upload"
                            />
                        </b-col>
                    </b-row>
                </b-col>
                <!-- PHOTO DISPLAY ENDS -->
            </b-row>
        </section>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BFormFile,
    BButton,
    BImg,
    BFormDatepicker,
} from "bootstrap-vue";

import axios from "axios";
import Images from "../data/images";
import Coordinates from "../data/coordinates";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BForm,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormFile,
        BButton,
        BImg,
        BFormDatepicker,
    },
    created() {
        // this.geolocate();
        // this.randomImg();
    },
    data() {
        return {
            displayImg: [],
            file: null,
            link: null,
            inputLat: null,
            inputLng: null,
            date: null,
            center: { lat: 22.302711, lng: 114.177216 },
            currentPlace: {},
            infoBox: {
                position: { lat: 22.302711, lng: 114.177216 },
                open: false,
            },
        };
    },

    methods: {
        // Random Images
        randomImg() {
            this.displayImg = [];
            for (let i = 0; i < 9; i++) {
                const randint =
                    Math.floor(Math.random() * (Images.length - 1)) + 1;
                this.displayImg.push(Images[randint]);
            }
        },
        // File Upload
        fileUpload() {
            const formData = new FormData();
            formData.append("file", this.file);
            const headers = { "Content-Type": "multipart/form-data" };
            axios
                .post(
                    "https://file.starsnet.com.hk/api/upload/bucket/development",
                    formData,
                    {
                        headers,
                    }
                )
                .then((res) => {
                    this.link = res.data;
                });
        },
        // Google Map Related
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                let k = {
                    position: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                };
                this.currentPlace = k;
            });
        },
        toggle(marker) {
            this.infoBox.position.lat = marker.position.lat;
            this.infoBox.position.lng = marker.position.lng;
            this.infoBox.open = true;
            this.randomImg();
        },
    },
    computed: {
        // Google Map Markers
        markers() {
            let output = [];
            for (let i = 0; i < Coordinates.length; i++) {
                const position = {
                    lat: 22.302711 + Math.random() / 10 - Math.random() / 10,
                    lng: 114.177216 + Math.random() / 10 - Math.random() / 10,
                };
                position = Coordinates[i];
                output.push({ position: position });
            }
            return output;
        },
    },
};
</script>

//
<style lang="scss">
@import "@core/scss/vue/pages/page-knowledge-base.scss";
</style>
