const coordinates = [
    { lat: 22.4630358, lng: 114.3304222 },
    { lat: 22.3754748, lng: 114.184614 },
    { lat: 22.4305015, lng: 114.2086707 },
    { lat: 22.3378426, lng: 114.2690644 },
    { lat: 22.207381, lng: 114.2584361 },
    { lat: 22.3201344, lng: 114.1548995 },
    { lat: 22.3673467, lng: 114.1098142 },
    { lat: 22.2717172, lng: 114.2453731 },
    { lat: 22.3673582, lng: 113.9503006 },
    { lat: 22.4557792, lng: 114.0320614 },
    { lat: 22.28312306, lng: 114.1847904 },
    { lat: 22.290436, lng: 114.1396169 },
    { lat: 22.33534882, lng: 114.1938203 },
    { lat: 22.3919219, lng: 114.1999774 },
    { lat: 22.46882208, lng: 113.9810717 },
    { lat: 22.2422064, lng: 114.187525 },
];

export default coordinates;
